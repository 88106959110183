import requestHandler from './requestHandler';

// Request handler for emailVerification
/**
 * @param {object} emailAddress - Learners email, needed to send the verification code
 * @param {string} [token  = undefined] The JwT bearer token used for authentication issued by Rustici
 * @returns The result of the operation
 */
const emailVerification = (() => {
    enum EmailFlags {
        EMAIL_SENT = 'EMAIL_SENT',
        EMAIL_FAILED_TO_SEND = 'EMAIL_FAILED_TO_SEND',
        USER_VERIFIED = 'USER_VERIFIED',
    }

    const sendVerificationEmail = async (emailAddress: string, token: string): Promise<string> => {
        try {
            const res = await requestHandler.sendVerificationEmail(emailAddress, token);
            if (res.result) {
                return EmailFlags.EMAIL_SENT;
            } else {
                // Validation to avoid any override due to racing conditions
                if (res.message === 'UserVerified') {
                    return EmailFlags.USER_VERIFIED;
                }
                return EmailFlags.EMAIL_FAILED_TO_SEND;
            }
        } catch (err) {
            console.error(err);
            throw new Error(EmailFlags.EMAIL_FAILED_TO_SEND);
        }
    };

    const getEmailFlags = () => {
        return EmailFlags;
    };

    return {
        sendVerificationEmail,
        getEmailFlags,
    };
})();

export default emailVerification;
