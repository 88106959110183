import { en } from '../utils/en';

// Initial handler for localization, defaults in English
// To add languages, classes need to be added in utils path
export const messageHandler = (() => {
    const DEFAULT_LANGUAGE = 'English';
    const getMessage = (key: string) => {
        switch (DEFAULT_LANGUAGE) {
            case 'English':
                return en[key];
            default:
                return en[key];
        }
    };

    return {
        getMessage,
    };
})();

export default messageHandler;